import * as Sentry from '@sentry/browser'
import { enableES5 } from 'immer'
import { AppProps, default as NextApp } from 'next/app'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import { withRouter } from 'next/router'
import React from 'react'
import env from '~/constants/env'

import 'cropperjs/dist/cropper.css'
import 'quill/dist/quill.snow.css'

const TopProgressBar = dynamic(
  () => {
    return import('../components/common/ProgressBar/TopProgressBar')
  },
  { ssr: false }
)

enableES5()

const PUBLIC_ASSET_PREFIX = env.PUBLIC_ASSET_PREFIX

import Notifications from '~/components/common/Notice/Notice'
import { NoticeProvider } from '~/context/NoticeContext'
import { NoticeInlineProvider } from '~/context/NoticeInlineContext'

type Props = AppProps

interface TouchEvent extends Event {
  scale?: number
}

if (typeof window !== 'undefined') {
  Sentry.init({
    dsn: env.PUBLIC_SENTRY_DSN,
    debug: env.PUBLIC_SENTRY_DEBUG,
    release: env.PUBLIC_APP_VERSION,
    environment: env.PUBLIC_APP_ENVIRONMENT,
    whitelistUrls: [/https?:\/\/((static|assets|www)\.)?joblist(-dev)?\.com/],
    beforeSend: event => {
      // Network errors such as going offline or being blocked by a proxy.
      if (event.message && event.message.match(/Network error/i)) {
        return null
      }
      return event
    }
  })
}

class JobListApp extends NextApp<Props> {
  public static displayName = 'JobListApp'

  public registerGtmPageView() {
    setTimeout(() => {
      const globalNs = window as any
      globalNs.dataLayer = globalNs.dataLayer || []
      globalNs.dataLayer.push({
        event: 'Pageview',
        pagePath: this.props.router.asPath,
        pageTitle: document.title
      })
    }, 0)
  }

  public registerBingPageView() {
    setTimeout(() => {
      const globalNs = window as any
      globalNs.uetq = globalNs.uetq || []
      globalNs.uetq.push('event', 'page_view', { page_path: this.props.router.asPath })
    }, 0)
  }

  public componentDidMount(): void {
    if (typeof document === 'object') {
      document.addEventListener(
        'touchmove',
        (event: TouchEvent) => {
          if (event && event.scale !== 1) {
            event.preventDefault()
          }
        },
        false
      )
    }
  }
  public componentDidUpdate() {
    this.registerGtmPageView()
    this.registerBingPageView()
  }
  public componentDidCatch(error: Error, errorInfo: Record<string, any>) {
    Sentry.withScope(scope => {
      for (const key in errorInfo) {
        if (errorInfo.hasOwnProperty(key)) {
          scope.setExtra(key, errorInfo[key])
        }
      }

      const parts = (document.cookie || '').split(';')
      for (let i = 0; i < parts.length; i++) {
        const part = parts[i].trim()
        const index = part.indexOf('=')
        if (index !== -1 && part.length < 150) {
          scope.setExtra(part.substring(0, index), part.substring(index + 1))
        }
      }
      scope.setTag('component', '_app')
      Sentry.captureException(error)
    })
    if (super.componentDidCatch !== undefined) {
      super.componentDidCatch(error, errorInfo as React.ErrorInfo)
    }
  }

  public render() {
    const { Component, pageProps } = this.props
    return (
      <NoticeProvider>
        <NoticeInlineProvider>
          <Head>
            <meta httpEquiv="Content-Language" content="en" />
            <meta property="og:type" content="website" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, shrink-to-fit=no"
            />
            <meta key="meta-og-img-width" property="og:image:width" content="600" />
            <meta key="meta-og-img-height" property="og:image:height" content="300" />
            <meta
              key="meta-og-img"
              property="og:image"
              content={`${PUBLIC_ASSET_PREFIX}/static/Joblist_Logo_Black.png`}
            />
          </Head>
          <div id="noticePortalBottomRight" className="noticePortalUi" />
          <div id="noticePortalBottomLeft" className="noticePortalUi" />
          <div id="noticePortalBottomCenter" className="noticePortalUi" />
          <div id="noticePortalTopRight" className="noticePortalUi" />
          <div id="noticePortalTopLeft" className="noticePortalUi" />
          <div id="noticePortalTopCenter" className="noticePortalUi" />
          <div id="popitPortal" />
          <TopProgressBar />
          <Component {...pageProps} />
          <Notifications />
        </NoticeInlineProvider>
      </NoticeProvider>
    )
  }
}

JobListApp.getInitialProps = async appContext => {
  // calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProps = await NextApp.getInitialProps(appContext)
  return { ...appProps }
}

export default withRouter(JobListApp)
