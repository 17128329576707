// COLORS
export const PURPLE = '#8053fd'
export const PURPLE_LIGHT = '#e3def9'
export const PURPLE_DARK = '#7e5bd8'
export const BLUE = '#2ABCE0'
export const BLUE_DARK = '#009ec5'
export const BLUE_GOOGLE = '#1a0dab'
export const YELLOW = '#fdcf38'
export const YELLOW_DARK = '#f3cb36'
export const ORANGE = '#ef8945'
export const GREEN = '#5dc86e'
export const RED_LIGHT = '#F9E7E7'
export const RED = '#CA2424'
// GRAYS
export const WHITE = '#fff'
export const GRAY_LIGHT = '#e7e9f0'
export const GRAY_MEDIUM_LIGHT = '#f6f7fa'
export const GRAY_MEDIUM = '#839399'
export const GRAY_MEDIUM_DARK = '#526269'
export const GRAY_DARK = '#828282'
export const GRAY_DARKER = '#1b1f1f'
export const BLACK = '#0e1111'

// FONT FAMILY
export const MAIN_FONT_FAMILY = "'Lato', 'Lucida Grande', sans-serif"

// SCREEN SIZES
export const SCREEN_DESKTOP = '1199px'
export const SCREEN_TABLET = '991px'
export const SCREEN_MOBILE = '767px'

// MEDIA QUERIES
export const ALL = 'only screen and (max-width: 70000px)'
export const MOBILE = 'only screen and (max-width: 767px)'
export const TABLET_ONLY = 'only screen and (min-width: 768px) and (max-width: 991px)'
export const TABLET_OR_LESS = 'only screen and (max-width: 991px)'
export const TABLET_OR_MORE = 'only screen and (min-width: 768px)'
export const DESKTOP_ONLY = 'only screen and (min-width: 992px) and (max-width: 1199px)'
export const DESKTOP_OR_LESS = 'only screen and (max-width: 1199px)'
export const DESKTOP_OR_MORE = 'only screen and (min-width: 992px)'
export const DESKTOP_WIDE = 'only screen and (min-width: 1200px)'
export const DESKTOP_EXTRA_WIDE = 'only screen and (min-width: 1600px)'

export type screenSizeType =
  | 'all'
  | 'xs'
  | 'md'
  | 'mdDown'
  | 'mdUp'
  | 'lg'
  | 'lgDown'
  | 'lgUp'
  | 'xl'

interface ImqSizeMap {
  all: string
  xs: string
  md: string
  mdDown: string
  mdUp: string
  lg: string
  lgDown: string
  lgUp: string
  xl: string
}

export const mqSizeMap: ImqSizeMap = {
  all: ALL,
  xs: MOBILE,
  md: TABLET_ONLY,
  mdDown: TABLET_OR_LESS,
  mdUp: TABLET_OR_MORE,
  lg: DESKTOP_ONLY,
  lgDown: DESKTOP_OR_LESS,
  lgUp: DESKTOP_OR_MORE,
  xl: DESKTOP_WIDE
}

// SHADOW
export const BUTTON_SHADOW = '0px 0px 9px 0px rgba(0, 0, 0, 0.15)'
export const RAISED_SHADOW =
  '0 1.33px 9.63px 0 rgba(53, 58, 60, 0.03), 0 2.67px 11.07px 0 rgba(42, 188, 224, 0.04), 0 4px 12px 0 rgba(53, 58, 60, 0.02)'
export const RAISED_HOVER_SHADOW =
  '0 1.33px 9.63px 0 rgba(53, 58, 60, 0.04), 0 2.67px 11.07px 0 rgba(42, 188, 224, 0.04), 0 4px 12px 0 rgba(53, 58, 60, 0.04)'

// FONT SIZE MOBILE
export const MOD_FS_HEADER = 4.6
export const MOB_FS_TEXT = 1.9
export const MOB_HEADER_3 = 3.5
export const MOB_HEADER_4 = 2.2

// FONT SIZE TABLET
export const TAB_FS_HEADER = 6.5
// # sum <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
export const TAB_FS_SUB_HEADER_2 = 2.8
// <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

export const TAB_FS_TEXT = 1.6
export const TAB_FS_TEXT_2 = 1.4
export const TAB_FS_INPUT = 1.5
export const TAB_FS_BUTTON_2 = 1.2

// FONT SIZE DESKTOP
export const DES_FS_HEADER = 9
export const DES_FS_SUB_HEADER = 4
export const DES_FS_TEXT = 2
export const DES_FS_INPUT = 1.7

// # sum ************************************
export const DES_FS_BUTTON_FIND = 1.3
// ******************************************

export const CONTENT_LINE_HEIGHT = 1.2
export const HEADER_LINE_HEIGHT = 1.1
