import React, { useEffect } from 'react'
import {
  useNoticeState,
  useNoticeActions,
  NoticeProps,
  NoticePositions,
  NoticeColor
} from '../../../context/NoticeContext'
import { css } from '@emotion/core'
import Button from '../Button'
import { Portal } from '../Portal'
import { BLACK, GRAY_MEDIUM_DARK } from '~/constants/theme.styles'

export const Notice: React.FC<NoticeProps> = ({ children, ...noticeProps }) => {
  const { createNotice } = useNoticeActions()
  useEffect(() => {
    createNotice(children, noticeProps)
  }, [])
  return null
}

interface NoticeUiProps {
  id: string
  element: React.ReactNode
  noticeProps: {
    color?: NoticeColor
    onOpen?: () => void
    onClose?: () => void
    timeout?: number
    position?: NoticePositions
  }
}

const noticeContainerStyles = css`
  position: relative;
  width: 100%;
  min-height: 70px;
  /* background: #2f2f2f;
  color: #fff; */
  z-index: 999999;
  padding: 15px;
  font-size: 15px;
  border-radius: 5px;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 375px;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 375px) {
    padding: 20px;
  }
`
const closeButtonStyles = css`
  position: absolute;
  right: 8px;
  top: 7px;
  font-size: 18px;
  opacity: 0.8;
`

const closeButtonColorStyles = (color: NoticeColor) => {
  if (color === 'light') {
    return css`
      color: ${GRAY_MEDIUM_DARK};
      :hover {
        color: ${BLACK};
      }
    `
  } else {
    return css`
      color: #e6e6e6;
      :hover {
        color: #fff;
      }
    `
  }
}

const colorStyles = (color: NoticeColor) => {
  if (color === 'light') {
    return css`
      background: #fff;
      color: ${BLACK};
    `
  } else {
    return css`
      background: #2f2f2f;
      color: #fff;
    `
  }
}

const NoticeUi: React.FC<NoticeUiProps> = ({ id, element: Element, noticeProps }) => {
  const { onOpen, onClose, timeout, color = 'dark' } = noticeProps
  const { removeNotice } = useNoticeActions()

  useEffect(() => {
    let timer: any = null
    if (timeout) {
      timer = setTimeout(() => {
        removeNotice(id)
      }, timeout)
    }
    return () => {
      if (timeout) {
        clearTimeout(timer)
      }
    }
  }, [])
  useEffect(() => {
    if (onOpen) {
      onOpen()
    }
  }, [])
  return (
    <div className="noticeUi" css={[noticeContainerStyles, colorStyles(color)]}>
      <Button
        id={id}
        onClick={event => {
          const element = event.currentTarget
          const id = element.getAttribute('id')
          if (id) {
            removeNotice(id)
          }
          if (typeof onClose === 'function') {
            onClose()
          }
        }}
        css={[closeButtonStyles, closeButtonColorStyles(color)]}
        unstyled
      >
        &times;
      </Button>
      {Element}
    </div>
  )
}

const Notifications: React.FC = () => {
  const noticeCollection = useNoticeState()

  return (
    <>
      {noticeCollection.map(({ id, Element, noticeProps }, index) => {
        const position = noticeProps.position
          ? noticeProps.position.charAt(0).toUpperCase() + noticeProps.position.slice(1)
          : 'BottomRight'

        return (
          <Portal key={id + index} selector={`#noticePortal${position}`}>
            <NoticeUi id={id} element={Element} noticeProps={noticeProps} />
          </Portal>
        )
      })}
    </>
  )
}

export default Notifications
