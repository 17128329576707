import React, { createContext, useState, useEffect } from 'react'
import uid from 'uid'
import Router from 'next/router'

export type NoticePositions =
  | 'topRight'
  | 'topLeft'
  | 'topCenter'
  | 'bottomRight'
  | 'bottomLeft'
  | 'bottomCenter'

export type NoticeColor = 'light' | 'dark'

export type NoticeProps = {
  position?: NoticePositions
  color?: NoticeColor
  onClose?: () => void
  onOpen?: () => void
  timeout?: number
  id?: string
  showSoloNotice?: boolean
  // persist notice across page views
  persist?: boolean
}

type NoticeActions = {
  createNotice: (content: React.ReactNode, noticeProps?: NoticeProps) => string
  removeNotice: (id: string) => void
  removeAllNotices: () => void
  hasNotice: (id: string) => boolean
  hasAnyVisibleNotice: () => boolean
}
export type NoticeType = {
  id: string
  Element: React.ReactNode
  noticeProps: NoticeProps
}
type NoticeCollection = NoticeType[]
const NoticeStateContext = createContext<NoticeCollection>([])
const NoticeActionsContext = createContext<NoticeActions | undefined>(undefined)

const NoticeProvider: React.FC = ({ children }) => {
  const [noticeCollection, setNoticeCollection] = useState<NoticeCollection>([])

  const handleRouteChange = () => {
    setNoticeCollection(prevState => {
      return prevState.filter(item => item.noticeProps.persist)
    })
  }

  useEffect(() => {
    Router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [noticeCollection])

  const createNotice = (content: React.ReactNode, noticeProps: NoticeProps = {}) => {
    const elementId = noticeProps.id || uid()
    if (noticeProps.showSoloNotice) {
      setNoticeCollection([{ id: elementId, Element: content, noticeProps }])
    } else {
      setNoticeCollection(prevState => [
        ...prevState.filter(item => item.id !== elementId),
        { id: elementId, Element: content, noticeProps }
      ])
    }

    return elementId
  }

  const removeAllNotices = () => {
    setNoticeCollection([])
  }

  const removeNotice = (id: string) => {
    setNoticeCollection(prevState => {
      return prevState.filter(item => item.id !== id)
    })
  }

  const hasNotice = (id: string) => {
    return noticeCollection.some(notice => notice.id === id)
  }

  const hasAnyVisibleNotice = () => {
    return noticeCollection.length > 0
  }

  return (
    <NoticeStateContext.Provider value={noticeCollection}>
      <NoticeActionsContext.Provider
        value={{ createNotice, removeNotice, removeAllNotices, hasNotice, hasAnyVisibleNotice }}
      >
        {children}
      </NoticeActionsContext.Provider>
    </NoticeStateContext.Provider>
  )
}

function useNoticeState() {
  const context = React.useContext(NoticeStateContext)
  if (context === undefined) {
    throw new Error('useNoticeState must be used within a NoticeProvider')
  }
  return context
}

function useNoticeActions() {
  const context = React.useContext(NoticeActionsContext)
  if (context === undefined) {
    throw new Error('useNoticeActions must be used within a NoticeProvider')
  }
  return context
}

function useNotice(): [React.ReactNode[], NoticeActions] {
  return [useNoticeState(), useNoticeActions()]
}
interface ConsumerProps {
  children: (context: React.ReactNode[] | undefined) => React.ReactNode
}
const NoticeConsumer: React.FC<ConsumerProps> = ({ children }) => {
  return (
    <NoticeStateContext.Consumer>
      {context => {
        if (context === undefined) {
          throw new Error('NoticeConsumer must be used within a NoticeProvider')
        }
        return children(context)
      }}
    </NoticeStateContext.Consumer>
  )
}

export { NoticeProvider, NoticeConsumer, useNotice, useNoticeState, useNoticeActions }
