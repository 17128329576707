import React from 'react'
import { css } from '@emotion/core'
import cx from 'classnames'
import Button, { IButtonProps } from '../Button/Button'
import { EmotionStyles } from '~/types/types'
import {
  BLACK,
  BLUE,
  BLUE_DARK,
  GRAY_DARKER,
  GRAY_MEDIUM,
  GRAY_MEDIUM_LIGHT,
  PURPLE,
  PURPLE_DARK,
  YELLOW,
  GREEN,
  RED,
  RED_LIGHT
} from '../../../constants/theme.styles'
import dynamic from 'next/dynamic'

const DynamicSearchIcon = dynamic(() => import('./SearchIcon'))
const DynamicSearchIconNew = dynamic(() => import('./SearchIconNew'))
const DynamicTwitterIcon = dynamic(() => import('./TwitterIcon'))
const DynamicFacebookIcon = dynamic(() => import('./FacebookIcon'))
const DynamicLinkedinIcon = dynamic(() => import('./LinkedinIcon'))
const DynamicInstagramIcon = dynamic(() => import('./InstagramIcon'))
const DynamicCloseIcon = dynamic(() => import('./CloseIcon'))
const DynamicCancelCircleIcon = dynamic(() => import('./CancelCircle'))
const DynamicCouponIcon = dynamic(() => import('./CouponIcon'))
const DynamicStopIcon = dynamic(() => import('./StopIcon'))
const DynamicArrowIcon = dynamic(() => import('./ArrowIcon'))
const DynamicArrowReverseIcon = dynamic(() => import('./ArrowReverseIcon'))
const DynamicChevronIcon = dynamic(() => import('./ChevronIcon'))
const DynamicDotsIcon = dynamic(() => import('./DotsIcon'))
const DynamicJobIcon = dynamic(() => import('./JobIcon'))
const DynamicCopyIcon = dynamic(() => import('./CopyIcon'))
const DynamicSpinnerIcon = dynamic(() => import('./SpinnerIcon'))
const DynamicCogIcon = dynamic(() => import('./CogIcon'))
const DynamicCheckmarkIcon = dynamic(() => import('./CheckmarkIcon'))
const DynamicCircleCheckIcon = dynamic(() => import('./CircleCheckIcon'))
const DynamicCheckoutIcon = dynamic(() => import('./CheckoutIcon'))
const DynamicPinIcon = dynamic(() => import('./PinIcon'))
const DynamicPinIconNew = dynamic(() => import('./PinIconNew'))
const DynamicEyeIconNew = dynamic(() => import('./EyeIcon'))
const DynamicProgressIconNew = dynamic(() => import('./ProgressIcon'))
const DynamicKeyIconNew = dynamic(() => import('./KeyIcon'))
const DynamicMailIconNew = dynamic(() => import('./MailIcon'))
const DynamicMailAlertIconNew = dynamic(() => import('./MailAlertIcon'))
const DynamicProfileIcon = dynamic(() => import('./ProfileIcon'))
const DynamicProfileCircleIcon = dynamic(() => import('./ProfileCircleIcon'))
const DynamicPreviewIcon = dynamic(() => import('./PreviewIcon'))
const DynamicSignOutIcon = dynamic(() => import('./SignOutIcon'))
const DynamicRefreshIcon = dynamic(() => import('./RefreshIcon'))
const DynamicHeartIcon = dynamic(() => import('./HeartIcon'))
const DynamicFilledHeartIcon = dynamic(() => import('./FilledHeartIcon'))
const DynamicDollarIcon = dynamic(() => import('./DollarIcon'))
const DynamicEditIcon = dynamic(() => import('./EditIcon'))
const DynamicTrashIcon = dynamic(() => import('./TrashIcon'))
const DynamicCelebrateIcon = dynamic(() => import('./CelebrateIcon'))
const DynamicInfoIcon = dynamic(() => import('./InfoIcon'))
const DynamicInvoiceIcon = dynamic(() => import('./InvoiceIcon'))
const DynamicLinkIcon = dynamic(() => import('./LinkIcon'))
const DynamicPeopleIcon = dynamic(() => import('./PeopleIcon'))
const DynamicPlusIcon = dynamic(() => import('./PlusIcon'))
const DynamicBlockIcon = dynamic(() => import('./BlockIcon'))
const DynamicSearchPreviousIcon = dynamic(() => import('./SearchPreviousIcon'))
const DynamicCaseIcon = dynamic(() => import('./CaseIcon'))
const DynamicBadgeIcon = dynamic(() => import('./BadgeIcon'))
const DynamicLightBulbIcon = dynamic(() => import('./LightBulbIcon'))
const DynamicSalaryIcon = dynamic(() => import('./SalaryIcon'))
const DynamicStatsIcon = dynamic(() => import('./StatsIcon'))

const NewArrowIcon = dynamic(() => import('./NewArrowIcon'))

const DynamicMapIcon = dynamic(() => import('./MapIcon'))

export type Alignment = 'top' | 'middle' | 'bottom'
export type Size = 'massive' | 'huge' | 'big' | 'large' | 'medium' | 'small' | 'tiny' | 'mini'
export type IconNames =
  | 'search'
  | 'searchNew'
  | 'twitter'
  | 'facebook'
  | 'linkedin'
  | 'instagram'
  | 'close'
  | 'cancel'
  | 'chevron'
  | 'coupon'
  | 'dots'
  | 'job'
  | 'arrow'
  | 'arrowReverse'
  | 'copy'
  | 'spinner'
  | 'cog'
  | 'checkmark'
  | 'circleCheck'
  | 'checkout'
  | 'pin'
  | 'pinNew'
  | 'eye'
  | 'progress'
  | 'key'
  | 'mail'
  | 'mailAlert'
  | 'profile'
  | 'profileCircle'
  | 'signout'
  | 'stop'
  | 'refresh'
  | 'heart'
  | 'filledHeart'
  | 'dollar'
  | 'edit'
  | 'trash'
  | 'celebrate'
  | 'info'
  | 'invoice'
  | 'link'
  | 'people'
  | 'plus'
  | 'preview'
  | 'searchPrevious'
  | 'case'
  | 'badge'
  | 'block'
  | 'newArrow'
  | 'map'
  | 'case'
  | 'lightBulb'
  | 'salary'
  | 'stats'

export type Color =
  | 'white'
  | 'light'
  | 'dark'
  | 'black'
  | 'blue'
  | 'red'
  | 'purple'
  | 'purpleDark'
  | 'yellow'
  | 'green'
  | 'lightGray'

interface IconProps {
  className?: string
  element?: React.ElementType<any>
  children?: React.ReactNode
  ref?: React.Ref<any>
  // extras
  icon: IconNames | React.ReactElement<any>
  size?: Size
  circular?: boolean
  fluid?: boolean
  verticalAlign?: Alignment
  color?: Color
  rotate?: number
  spin?: boolean
  spinDuration?: number
  customCss?: EmotionStyles
}

// =====
// Standard props
type IconBaseProps = {
  element?: React.ElementType<any>
} & IconProps

// Anchor props
type IconAnchorProps = React.PropsWithoutRef<JSX.IntrinsicElements['a']> & {
  element: 'a'
} & IconProps

// Button props
type IconButtonProps = React.PropsWithoutRef<JSX.IntrinsicElements['button']> & {
  element: 'button'
} & IconProps

type IconButtonElementProps = IButtonProps & {
  element: typeof Button
} & IconProps

// Input/output options
export type IconOverload = {
  (props: IconBaseProps, ref: React.Ref<any>): JSX.Element
  (props: IconAnchorProps, ref: React.Ref<any>): JSX.Element
  (props: IconButtonProps, ref: React.Ref<any>): JSX.Element
  (props: IconButtonElementProps, ref: React.Ref<any>): JSX.Element
} & { displayName?: string }
// ======

const IconBase: IconOverload = (
  {
    element: Element = 'span',
    className,
    children,
    spin,
    spinDuration = 1000,
    size,
    icon,
    circular,
    fluid,
    verticalAlign,
    color = 'light',
    rotate = 0,
    customCss,

    ...rest
  }: IconBaseProps | IconAnchorProps | IconButtonProps,
  ref: React.Ref<any>
) => {
  const iconItemColor = !circular
    ? iconColors[color]
    : color === 'red'
    ? iconColors['red']
    : color === 'dark' ||
      color === 'black' ||
      color === 'blue' ||
      color === 'purple' ||
      color === 'purpleDark' ||
      color === 'yellow' ||
      color === 'green' ||
      color === 'lightGray'
    ? iconColors['white']
    : iconColors['black']

  const isHoverable = Element === 'a' || Element === 'button'
  const isCircularHoverable = circular && (Element === 'a' || Element === 'button')

  return (
    <Element
      ref={ref}
      className={cx('iconUi', 'js-icon', className)}
      type={Element === 'button' ? 'button' : null}
      css={[
        baseIconStyles,
        size ? sizeStyles(size) : null,
        circular ? circularStyles : null,
        circular ? circleBgColorStyles(color) : null,
        isHoverable ? hoverableStyles : null,
        isHoverable && !circular ? nonCircularHoverableStyles(color) : null,
        isCircularHoverable ? circularHoverableStyles : null,
        isCircularHoverable ? circularOnHoverStyles(color) : null,
        verticalAlign ? alignmentStyles(verticalAlign) : null,
        fluid ? fluidStyles : null,
        fluid && circular ? circularFluidStyles : null,
        spin ? spinStyles(spinDuration) : null,
        css`
          transform: rotate(${rotate}deg);
        `,
        customCss
      ]}
      {...rest}
    >
      {typeof icon !== 'string' ? (
        React.cloneElement(icon)
      ) : (
        <>
          {icon === 'search' && <DynamicSearchIcon color={iconItemColor} />}
          {icon === 'searchNew' && <DynamicSearchIconNew color={iconItemColor} />}
          {icon === 'twitter' && <DynamicTwitterIcon color={iconItemColor} />}
          {icon === 'facebook' && <DynamicFacebookIcon color={iconItemColor} />}
          {icon === 'linkedin' && <DynamicLinkedinIcon color={iconItemColor} />}
          {icon === 'instagram' && <DynamicInstagramIcon color={iconItemColor} />}
          {icon === 'close' && <DynamicCloseIcon color={iconItemColor} />}
          {icon === 'cancel' && <DynamicCancelCircleIcon color={iconItemColor} />}
          {icon === 'coupon' && <DynamicCouponIcon color={iconItemColor} />}
          {icon === 'stop' && <DynamicStopIcon color={iconItemColor} />}
          {icon === 'chevron' && <DynamicChevronIcon color={iconItemColor} />}
          {icon === 'dots' && <DynamicDotsIcon color={iconItemColor} />}
          {icon === 'job' && <DynamicJobIcon color={iconItemColor} />}
          {icon === 'arrow' && <DynamicArrowIcon color={iconItemColor} />}
          {icon === 'arrowReverse' && <DynamicArrowReverseIcon color={iconItemColor} />}
          {icon === 'copy' && <DynamicCopyIcon color={iconItemColor} />}
          {icon === 'spinner' && <DynamicSpinnerIcon color={iconItemColor} />}
          {icon === 'cog' && <DynamicCogIcon color={iconItemColor} />}
          {icon === 'checkmark' && <DynamicCheckmarkIcon color={iconItemColor} />}
          {icon === 'circleCheck' && <DynamicCircleCheckIcon color={iconItemColor} />}
          {icon === 'checkout' && <DynamicCheckoutIcon color={iconItemColor} />}
          {icon === 'pin' && <DynamicPinIcon color={iconItemColor} />}
          {icon === 'pinNew' && <DynamicPinIconNew color={iconItemColor} />}
          {icon === 'eye' && <DynamicEyeIconNew color={iconItemColor} />}
          {icon === 'progress' && <DynamicProgressIconNew color={iconItemColor} />}
          {icon === 'key' && <DynamicKeyIconNew color={iconItemColor} />}
          {icon === 'mail' && <DynamicMailIconNew color={iconItemColor} />}
          {icon === 'mailAlert' && <DynamicMailAlertIconNew color={iconItemColor} />}
          {icon === 'profile' && <DynamicProfileIcon color={iconItemColor} />}
          {icon === 'profileCircle' && <DynamicProfileCircleIcon color={iconItemColor} />}
          {icon === 'signout' && <DynamicSignOutIcon color={iconItemColor} />}
          {icon === 'refresh' && <DynamicRefreshIcon color={iconItemColor} />}
          {icon === 'heart' && <DynamicHeartIcon color={iconItemColor} />}
          {icon === 'filledHeart' && <DynamicFilledHeartIcon color={iconItemColor} />}
          {icon === 'dollar' && <DynamicDollarIcon color={iconItemColor} />}
          {icon === 'edit' && <DynamicEditIcon color={iconItemColor} />}
          {icon === 'trash' && <DynamicTrashIcon color={iconItemColor} />}
          {icon === 'celebrate' && <DynamicCelebrateIcon color={iconItemColor} />}
          {icon === 'info' && <DynamicInfoIcon color={iconItemColor} />}
          {icon === 'invoice' && <DynamicInvoiceIcon color={iconItemColor} />}
          {icon === 'link' && <DynamicLinkIcon color={iconItemColor} />}
          {icon === 'people' && <DynamicPeopleIcon color={iconItemColor} />}
          {icon === 'preview' && <DynamicPreviewIcon color={iconItemColor} />}
          {icon === 'plus' && <DynamicPlusIcon color={iconItemColor} />}
          {icon === 'searchPrevious' && <DynamicSearchPreviousIcon color={iconItemColor} />}
          {icon === 'case' && <DynamicCaseIcon color={iconItemColor} />}
          {icon === 'badge' && <DynamicBadgeIcon color={iconItemColor} />}
          {icon === 'block' && <DynamicBlockIcon color={iconItemColor} />}
          {icon === 'newArrow' && <NewArrowIcon color={iconItemColor} />}
          {icon === 'map' && <DynamicMapIcon color={iconItemColor} />}
          {icon === 'lightBulb' && <DynamicLightBulbIcon color={iconItemColor} />}
          {icon === 'salary' && <DynamicSalaryIcon color={iconItemColor} />}
          {icon === 'stats' && <DynamicStatsIcon color={iconItemColor} />}
        </>
      )}
    </Element>
  )
}

IconBase.displayName = 'Icon'

const Icon = React.forwardRef(IconBase) as typeof IconBase

export default Icon

// STYLES
const baseIconStyles = css`
  width: 1em;
  height: 1em;
  margin: 0;
  display: inline-block;
  padding: 0;
  vertical-align: middle;
  svg {
    display: block;
    transform-origin: center center;
    width: 100%;
    height: 100%;
  }
`

const circularStyles = css`
  background: ${GRAY_MEDIUM_LIGHT};
  padding: 0.28em;
  border-radius: 50%;
`

// SIZE STYLES
interface ISizeMap {
  massive: number
  huge: number
  big: number
  large: number
  medium: number
  small: number
  tiny: number
  mini: number
}

const sizeMap: ISizeMap = {
  massive: 6.9,
  huge: 4.6,
  big: 3.6,
  large: 2.6,
  medium: 1.6,
  small: 1.4,
  tiny: 1.2,
  mini: 1
}

const sizeStyles = (size: Size) => {
  return css`
    font-size: ${sizeMap[size]}rem;
  `
}

// ALIGNMENT STYLES
const alignmentStyles = (alignment: Alignment) => {
  return css`
    vertical-align: ${alignment};
  `
}

// COLOR STYLES
interface IIconColors {
  white: string
  light: string
  dark: string
  black: string
  blue: string
  purple: string
  purpleDark: string
  red: string
  yellow: string
  green: string
  lightGray: string
}
const iconColors: IIconColors = {
  white: '#fff',
  light: GRAY_MEDIUM,
  dark: GRAY_DARKER,
  black: BLACK,
  blue: BLUE,
  purple: PURPLE,
  purpleDark: PURPLE_DARK,
  red: RED,
  yellow: YELLOW,
  green: GREEN,
  lightGray: '#ECEEF5'
}

const circleBgColors: IIconColors = {
  white: '#fff',
  light: GRAY_MEDIUM_LIGHT,
  dark: GRAY_DARKER,
  black: BLACK,
  blue: BLUE,
  purple: PURPLE,
  purpleDark: PURPLE_DARK,
  red: RED_LIGHT,
  yellow: YELLOW,
  green: GREEN,
  lightGray: '#ECEEF5'
}
const circleBgColorStyles = (color: Color) => {
  return css`
    background: ${circleBgColors[color]};
  `
}

const circularHoverColors: IIconColors = {
  white: '#efefef',
  light: '#f1f1f3',
  dark: '#333333',
  black: '#333333',
  blue: BLUE_DARK,
  purple: '#6c45d8',
  purpleDark: '#6a4db3',
  red: '#ffc5c5',
  yellow: '#f3c221',
  green: '#33C986',
  lightGray: '#ECEEF5'
}

const circularOnHoverStyles = (color: Color) => {
  return css`
    &:hover {
      background: ${circularHoverColors[color]};
    }
  `
}

// Anchor styles
const hoverableStyles = css`
  cursor: pointer;
  &:active,
  &:focus {
    outline: none;
  }
`
const nonCircularHoverableStyles = (color: Color) => css`
  background: transparent;
  :hover {
    svg,
    path {
      fill: ${color === 'light' ? BLACK : color};
    }
  }
`
const circularHoverableStyles = css`
  transition: all 0.3s ease;
`

// Fluid icons take full width of container
const fluidStyles = css`
  width: 100%;
  height: 0;
  padding: 100% 0 0 0;
  position: relative;
  svg {
    position: absolute;
    max-width: 100%;
    width: 100%;
    height: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`

const circularFluidStyles = css`
  svg {
    width: 55%;
  }
`

const spinStyles = (spinDuration: number) => css`
  animation-name: spinnerSpin;
  animation-duration: ${spinDuration}ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  @keyframes spinnerSpin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`
