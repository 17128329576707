import React from 'react'
import { css } from '@emotion/core'
import {
  GRAY_MEDIUM_LIGHT,
  MAIN_FONT_FAMILY,
  PURPLE,
  BLACK,
  GRAY_MEDIUM_DARK,
  GRAY_MEDIUM,
  RED,
  RED_LIGHT,
  GRAY_LIGHT
} from '../../../constants/theme.styles'
import Icon, { IconNames } from '../Icon/Icon'
import { EmotionStyles } from '../../../types/types'

const buttonBaseStyles = css`
  position: relative;
  font-family: ${MAIN_FONT_FAMILY};
  white-space: nowrap;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 1em;
  outline: 0;
  border: none;
  vertical-align: baseline;
  padding: 0.78571429em 1.5em 0.78571429em;
  text-transform: none;
  text-shadow: none;
  font-weight: 700;
  line-height: 1em;
  font-style: normal;
  text-align: center;
  text-decoration: none;
  border-radius: 0.4rem;
  user-select: none;
  will-change: '';
  -webkit-tap-highlight-color: transparent;
  transition: background-color 280ms cubic-bezier(0.4, 0, 0.2, 1), color 0.1s ease,
    border-color 280ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  &:disabled {
    cursor: default;
    opacity: 0.45;
    pointer-events: none !important;
  }
`
const fluidStyles = css`
  display: flex;
  width: 100%;
`

const raisedStyles = css`
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  &:hover,
  &:focus {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
      0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }
`

const disabledStyles = css`
  cursor: default;
  opacity: 0.45;
  pointer-events: none !important;
`

const arrowStyles = css`
  margin-left: 1.1em;
  left: 0;
  transition: left 0.2s;
  font-size: 0.65em;
  position: relative;
  button:hover &,
  a:hover & {
    left: 0.65em;
  }
`

const arrowReverseStyles = css`
  margin-right: 1.1em;
  left: 0;
  transition: left 0.2s;
  font-size: 0.65em;
  position: relative;
  button:hover &,
  a:hover & {
    left: -0.65em;
  }
`

interface ISizeMap {
  massive: number
  huge: number
  big: number
  large: number
  medium: number
  small: number
  tiny: number
  mini: number
}

const sizeMap: ISizeMap = {
  massive: 2.7,
  huge: 2.3,
  big: 2.1,
  large: 1.8,
  medium: 1.6,
  small: 1.4,
  tiny: 1.3,
  mini: 1.2
}

export type ButtonSize = 'massive' | 'huge' | 'big' | 'large' | 'medium' | 'small' | 'tiny' | 'mini'

const sizeStyles = (size: ButtonSize) => css`
  font-size: ${sizeMap[size]}rem;
`

const oversizeStyles = css`
  line-height: 2.35em;
`

export type ButtonColor = 'purple' | 'black' | 'gray' | 'white' | 'red' | 'lightGray'

const colorStyles = (color: ButtonColor, outline: boolean) => {
  if (color === 'black') {
    return css`
      color: ${outline ? BLACK : '#fff'};
      background-color: ${outline ? '#fff' : BLACK};
      border: 1px solid ${BLACK};

      &:hover,
      &:focus {
        color: ${outline ? BLACK : '#fff'};
        background-color: ${outline ? '#f1f1f1' : '#27292a'};
        border-color: ${outline ? BLACK : '#27292a'};
      }
      &:active {
        color: ${outline ? BLACK : '#fff'};
        background-color: ${outline ? '#f1f1f1' : '#343637'};
        border-color: ${outline ? BLACK : '#27292a'};
      }
    `
  }
  if (color === 'purple') {
    return css`
      color: ${outline ? PURPLE : '#fff'};
      background-color: ${outline ? '#fff' : PURPLE};
      border: 1px solid ${PURPLE};

      &:hover,
      &:focus {
        color: ${outline ? PURPLE : '#fff'};
        background-color: ${outline ? '#f4f0ff' : '#8660e8'};
        border-color: ${outline ? PURPLE : '#8660e8'};
      }
      &:active {
        color: ${outline ? PURPLE : '#fff'};
        background-color: ${outline ? '#f4f0ff' : '#7f5adc'};
        border-color: ${outline ? PURPLE : '#8660e8'};
      }
    `
  }
  if (color === 'red') {
    return css`
      color: ${RED};
      background-color: ${outline ? '#ffffff' : RED_LIGHT};
      border: 1px solid ${outline ? RED : RED_LIGHT};

      &:hover,
      &:focus {
        color: ${outline ? RED : '#fff'};
        background-color: ${outline ? RED_LIGHT : RED};
        border-color: ${RED};
        svg,
        path {
          fill: ${outline ? RED : '#ffffff'};
        }
      }
      &:active {
        color: ${outline ? RED : '#fff'};
        background-color: ${outline ? RED_LIGHT : RED};
        border-color: ${RED};
      }
    `
  }
  if (color === 'gray') {
    return css`
      color: ${outline ? GRAY_MEDIUM_DARK : BLACK};
      background-color: ${outline ? '#fff' : GRAY_MEDIUM_LIGHT};
      border: 1px solid ${outline ? GRAY_MEDIUM : GRAY_MEDIUM_LIGHT};

      &:hover,
      &:focus {
        color: ${outline ? GRAY_MEDIUM_DARK : BLACK};
        background-color: #eeeeee;
        border-color: ${outline ? GRAY_MEDIUM : '#eeeeee'};
      }
      &:active {
        color: ${outline ? GRAY_MEDIUM_DARK : BLACK};
        background-color: #dedede;
        border-color: ${outline ? GRAY_MEDIUM : '#F7F7F7'};
      }
    `
  }
  if (color === 'lightGray') {
    return css`
      color: ${outline ? GRAY_LIGHT : '#585E69'};
      background-color: ${outline ? '#fff' : GRAY_LIGHT};
      border: 1px solid ${outline ? GRAY_MEDIUM_LIGHT : GRAY_LIGHT};

      &:hover,
      &:focus {
        background-color: #dedede;
        border-color: ${outline ? GRAY_MEDIUM_LIGHT : '#F7F7F7'};
      }
      &:active {
        background-color: #dedede;
        border-color: ${outline ? GRAY_MEDIUM_LIGHT : '#F7F7F7'};
      }
    `
  }
  if (color === 'white') {
    return css`
      color: ${BLACK};
      background-color: #fff;
      border: 1px solid #fff;

      &:hover,
      &:focus,
      &:active {
        color: ${BLACK};
        background-color: ${outline ? '#ffffff' : '#F7F7F7'};
        border-color: ${outline ? GRAY_MEDIUM : '#F7F7F7'};
      }
    `
  }
}

const unstyledStyles = css`
  background: transparent;
  color: inherit;
  padding: 0;
  border-radius: 0;
  border: none;
  &:hover,
  &:focus {
    background-color: transparent;
    color: inherit;
  }
  &:active {
    background-color: transparent;
    color: inherit;
  }
`

const outlineStyles = css`
  padding-left: 1em;
  padding-right: 1em;
`

const roundedStyles = css`
  border-radius: 40px;
`

const roundedIconLeftStyles = css`
  margin-left: 0;
`

const roundedIconRightStyles = css`
  margin-right: 0;
`

const iconStyles = css`
  flex-shrink: 0;
  position: relative;
`
const iconLeftStyles = css`
  margin-left: -0.25em;
  margin-right: 0.5em;
`
const iconRightStyles = css`
  margin-left: 0.5em;
  margin-right: -0.25em;
`

const loadingStyles = css`
  transition: background-color 280ms cubic-bezier(0.4, 0, 0.2, 1), color 0.1s ease,
    border-color 280ms cubic-bezier(0.4, 0, 0.2, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  z-index: 10;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 0.4rem;
`

const oversizedLoadingStyles = css`
  font-size: 1.25em;
`

const uppercaseStyles = css`
  text-transform: uppercase;
`

const wrapStyles = css`
  padding-top: 0;
  padding-bottom: 0;
  min-height: 45px;
`
const textWrapStyles = css`
  padding-top: 4px;
  padding-bottom: 5px;
  white-space: normal;
`

export type AttachedOptions = boolean | 'top' | 'bottom'
const attachedStyles = (attachType: AttachedOptions) => {
  if (attachType === 'top') {
    return css`
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `
  }
  if (attachType === true) {
    return css`
      margin-top: -1px;
      border-radius: 0;
    `
  }
  if (attachType === 'bottom') {
    return css`
      margin-top: -1px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    `
  }
}

export interface IButtonProps {
  element?: React.ElementType<any>
  fluid?: boolean
  raised?: boolean
  size?: ButtonSize
  oversize?: boolean
  attached?: AttachedOptions
  color?: ButtonColor
  disabled?: boolean
  unstyled?: boolean
  buttonRef?: any
  outline?: boolean
  rounded?: boolean
  loading?: boolean
  arrow?: true
  arrowReverse?: true
  icon?: IconNames | React.ReactElement<any>
  iconPosition?: 'left' | 'right'
  loadingCss?: EmotionStyles
  uppercase?: boolean
  wrap?: boolean
  extraCss?: EmotionStyles
  childrenCss?: EmotionStyles
  children?: React.ReactNode
}

// =====
// Button props
export type ButtonProps = React.PropsWithoutRef<JSX.IntrinsicElements['button']> & {
  element?: React.ElementType<any>
} & IButtonProps

// Anchor props
export type AnchorProps = React.PropsWithoutRef<JSX.IntrinsicElements['a']> & {
  element: 'a'
} & IButtonProps

// Input/output options
export type ButtonOverload = {
  (props: ButtonProps, ref: React.Ref<any>): JSX.Element
  (props: AnchorProps, ref: React.Ref<any>): JSX.Element
} & { displayName?: string }
// ======

const ButtonBase: ButtonOverload = (
  {
    element: Element = 'button',
    fluid,
    raised,
    size = 'medium',
    oversize,
    attached = false,
    color = 'black',
    disabled,
    unstyled,
    children,
    buttonRef,
    outline = false,
    rounded = false,
    loading = false,
    icon,
    iconPosition = 'left',
    arrow,
    arrowReverse,
    uppercase,
    wrap = false,
    loadingCss,
    extraCss,
    childrenCss,
    ...rest
  }: ButtonProps | AnchorProps,
  ref: React.Ref<any>
) => {
  const nonOutlineIconColor =
    color === 'black' || color === 'purple' ? 'white' : color === 'red' ? 'red' : 'dark'
  const outlineIconColor =
    color === 'purple'
      ? 'purple'
      : color === 'black'
      ? 'black'
      : color === 'red'
      ? 'red'
      : color === 'gray'
      ? 'dark'
      : 'black'
  const iconColor = outline ? outlineIconColor : nonOutlineIconColor
  const isFluid = fluid || attached
  return (
    <Element
      ref={buttonRef}
      css={[
        // GENERAL STYLES
        buttonBaseStyles,
        // OUTLINE BUTTON TYPE STYLES
        outline ? outlineStyles : null,
        // ROUNDED BUTTON STYLES
        rounded ? roundedStyles : null,
        // FULL WIDTH FLUID STYLES
        isFluid ? fluidStyles : null,
        // RAISED STYLES
        raised ? raisedStyles : null,
        // SIZE STYLES
        sizeStyles(size),
        oversize ? oversizeStyles : null,
        // COLOR STYLES
        colorStyles(color, outline),
        // DISABLED STYLES
        disabled ? disabledStyles : null,
        // UNSTYLED BUTTON STYLES
        unstyled ? unstyledStyles : null,
        // UPPERCASE STYLES
        uppercase ? uppercaseStyles : null,
        // WRAPPING TEXT STYLES
        wrap ? wrapStyles : null,
        // ATTACHED STYLES
        attached ? attachedStyles(attached) : null,
        // EXTRA USER PROVIDED STYLES
        extraCss ? extraCss : null
      ]}
      {...rest}
    >
      {icon && iconPosition === 'left' ? (
        typeof icon !== 'string' ? (
          React.cloneElement(icon, {
            customCss: [
              children ? iconStyles : null,
              children ? iconLeftStyles : null,
              children && outline ? roundedIconLeftStyles : null
            ]
          })
        ) : (
          <Icon
            css={[
              children ? iconStyles : null,
              children ? iconLeftStyles : null,
              children && outline ? roundedIconLeftStyles : null
            ]}
            color={iconColor}
            icon={icon}
          />
        )
      ) : null}

      <span
        className="buttonTextUi"
        css={[
          wrap ? textWrapStyles : null,
          arrow || arrowReverse
            ? css`
                display: flex;
                align-items: center;
              `
            : null,
          childrenCss ? childrenCss : null
        ]}
      >
        {arrowReverse ? <Icon icon="arrowReverse" color="blue" css={arrowReverseStyles} /> : null}
        {children}
        {arrow ? <Icon icon="arrow" color="blue" css={arrowStyles} /> : null}
      </span>

      {icon && iconPosition === 'right' ? (
        typeof icon !== 'string' ? (
          React.cloneElement(icon, {
            customCss: [
              children ? iconStyles : null,
              children ? iconRightStyles : null,
              children && outline ? roundedIconRightStyles : null
            ]
          })
        ) : (
          <Icon
            css={[
              children ? iconStyles : null,
              children ? iconRightStyles : null,
              children && outline ? roundedIconRightStyles : null
            ]}
            color={iconColor}
            icon={icon}
          />
        )
      ) : null}

      {loading ? (
        <div
          css={[
            colorStyles(color, outline),
            loadingStyles,
            rounded ? roundedStyles : null,
            oversize ? oversizedLoadingStyles : null,
            loadingCss ? loadingCss : null
          ]}
        >
          <Icon color={iconColor} icon="spinner" />
        </div>
      ) : null}
    </Element>
  )
}

const Button = React.forwardRef(ButtonBase) as typeof ButtonBase

Button.displayName = 'Button'

export const AnchorButton = React.forwardRef(
  ({ children, ...rest }: Partial<AnchorProps>, ref: React.Ref<HTMLAnchorElement>) => (
    <Button buttonRef={ref} element="a" {...rest}>
      {children}
    </Button>
  )
)

AnchorButton.displayName = 'AnchorButton'

export default Button
