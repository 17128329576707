import React, { createContext, useState, useEffect } from 'react'
import uid from 'uid'
import Router from 'next/router'

export type NoticeInlineLocation = 'inline:header' | 'inline:jobPostingDetailsForm'
export type NoticeInlineProps = {
  onOpen?: () => void
  timeout?: number
  id?: string
  // persist noticeInline across page views
  persist?: boolean
  location?: NoticeInlineLocation
  // do not show the notice until there is at least one route change
  showCondition?: 'on:routeChangeComplete'
}

// 1. DONE - Render on next page view
// 2. DONE - Persist across all page views
// 4. TODO - Allow showing for x number of page views

type NoticeInlineActions = {
  createNoticeInline: (content: React.ReactNode, noticeInlineProps?: NoticeInlineProps) => string
  removeNoticeInline: (id: string) => void
  hasNoticeInline: (id: string) => boolean
  hasAnyVisibleNoticeInline: () => boolean
}
export type NoticeInlineType = {
  id: string
  Element: React.ReactNode
  noticeInlineProps: NoticeInlineProps
}
type NoticeInlineCollection = NoticeInlineType[]
const NoticeInlineStateContext = createContext<NoticeInlineCollection>([])
const NoticeInlineActionsContext = createContext<NoticeInlineActions | undefined>(undefined)

const NoticeInlineProvider: React.FC = ({ children }) => {
  const [noticeInlineCollection, setNoticeInlineCollection] = useState<NoticeInlineCollection>([])

  const handleRouteChange = () => {
    setNoticeInlineCollection(prevState => {
      return prevState.filter(item => {
        if (item.noticeInlineProps.showCondition === 'on:routeChangeComplete') {
          item.noticeInlineProps.showCondition = undefined
          return item
        }
        if (item.noticeInlineProps.persist) {
          return item
        }
      })
    })
  }

  useEffect(() => {
    Router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [noticeInlineCollection])

  const createNoticeInline = (
    content: React.ReactNode,
    noticeInlineProps: NoticeInlineProps = {}
  ) => {
    const elementId = noticeInlineProps.id || uid()
    setNoticeInlineCollection(prevState => [
      ...prevState.filter(item => item.id !== elementId),
      { id: elementId, Element: content, noticeInlineProps }
    ])

    return elementId
  }

  const removeNoticeInline = (id: string) => {
    setNoticeInlineCollection(prevState => {
      return prevState.filter(item => item.id !== id)
    })
  }

  const hasNoticeInline = (id: string) => {
    return noticeInlineCollection.some(noticeInline => noticeInline.id === id)
  }

  const hasAnyVisibleNoticeInline = () => {
    return noticeInlineCollection.length > 0
  }

  return (
    <NoticeInlineStateContext.Provider value={noticeInlineCollection}>
      <NoticeInlineActionsContext.Provider
        value={{
          createNoticeInline,
          removeNoticeInline,
          hasNoticeInline,
          hasAnyVisibleNoticeInline
        }}
      >
        {children}
      </NoticeInlineActionsContext.Provider>
    </NoticeInlineStateContext.Provider>
  )
}

function useNoticeInlineState() {
  const context = React.useContext(NoticeInlineStateContext)
  if (context === undefined) {
    throw new Error('useNoticeInlineState must be used within a NoticeInlineProvider')
  }
  return context
}

function useNoticeInlineActions() {
  const context = React.useContext(NoticeInlineActionsContext)
  if (context === undefined) {
    throw new Error('useNoticeInlineActions must be used within a NoticeInlineProvider')
  }
  return context
}

function useNoticeInline(): [React.ReactNode[], NoticeInlineActions] {
  return [useNoticeInlineState(), useNoticeInlineActions()]
}
interface ConsumerProps {
  children: (context: React.ReactNode[] | undefined) => React.ReactNode
}
const NoticeInlineConsumer: React.FC<ConsumerProps> = ({ children }) => {
  return (
    <NoticeInlineStateContext.Consumer>
      {context => {
        if (context === undefined) {
          throw new Error('NoticeInlineConsumer must be used within a NoticeInlineProvider')
        }
        return children(context)
      }}
    </NoticeInlineStateContext.Consumer>
  )
}

export {
  NoticeInlineProvider,
  NoticeInlineConsumer,
  useNoticeInline,
  useNoticeInlineState,
  useNoticeInlineActions
}
