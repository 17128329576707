export interface PublicEnv {
  PUBLIC_ADSENSE_CPARAM_CHANNEL_B: string | undefined
  PUBLIC_ADSENSE_CPARAM_CHANNEL: string | undefined
  PUBLIC_ADSENSE_DEFAULT_CHANNEL_B: string | undefined
  PUBLIC_ADSENSE_DEFAULT_CHANNEL: string | undefined
  PUBLIC_ADSENSE_FPARAM_CHANNEL_B: string | undefined
  PUBLIC_ADSENSE_FPARAM_CHANNEL: string | undefined
  PUBLIC_ADSENSE_PUBLISHER_ID: string | undefined
  PUBLIC_ADSENSE_STYLE_ID_TV6: string | undefined
  PUBLIC_ADSENSE_STYLE_ID: string | undefined
  PUBLIC_ADSENSE_TEST_MODE: boolean
  PUBLIC_APP_ENVIRONMENT: string | undefined
  PUBLIC_APP_VERSION: string | undefined
  PUBLIC_ASSET_PREFIX: string | undefined
  PUBLIC_BLOCK_CRAWLERS: boolean
  PUBLIC_COMPANY_IMAGE_HOST: string | undefined
  PUBLIC_ENABLE_BLOG: boolean
  PUBLIC_ENABLE_BROWSE_PAGE: boolean
  PUBLIC_ENABLE_NOTIFICATIONS_MODAL: boolean
  PUBLIC_FRONTEND_HOST: string | undefined
  PUBLIC_GOOGLE_MAPS_API_KEY: string | undefined
  PUBLIC_GQL_QUERY_TIMEOUT_MILLIS: number
  PUBLIC_GRAPHQL_BACKEND_ENDPOINT: string | undefined
  PUBLIC_GRAPHQL_GATEWAY_ENDPOINT: string | undefined
  PUBLIC_GTM_CONTAINER_ID: string | undefined
  PUBLIC_LOCAL: boolean
  PUBLIC_MIXPANEL_TOKEN: string | undefined
  PUBLIC_SENTRY_DEBUG: boolean
  PUBLIC_SENTRY_DSN: string | undefined
  PUBLIC_TEST_ENV: boolean
  PUBLIC_STRIPE_PUBLISHABLE_API_KEY: string | undefined
}

const process = global?.process

const publicEnv: PublicEnv = {
  PUBLIC_ADSENSE_CPARAM_CHANNEL_B: process?.env?.PUBLIC_ADSENSE_CPARAM_CHANNEL_B,
  PUBLIC_ADSENSE_CPARAM_CHANNEL: process?.env?.PUBLIC_ADSENSE_CPARAM_CHANNEL,
  PUBLIC_ADSENSE_DEFAULT_CHANNEL_B: process?.env?.PUBLIC_ADSENSE_DEFAULT_CHANNEL_B,
  PUBLIC_ADSENSE_DEFAULT_CHANNEL: process?.env?.PUBLIC_ADSENSE_DEFAULT_CHANNEL,
  PUBLIC_ADSENSE_FPARAM_CHANNEL_B: process?.env?.PUBLIC_ADSENSE_FPARAM_CHANNEL_B,
  PUBLIC_ADSENSE_FPARAM_CHANNEL: process?.env?.PUBLIC_ADSENSE_FPARAM_CHANNEL,
  PUBLIC_ADSENSE_PUBLISHER_ID: process?.env?.PUBLIC_ADSENSE_PUBLISHER_ID,
  PUBLIC_ADSENSE_STYLE_ID_TV6: process?.env?.PUBLIC_ADSENSE_STYLE_ID_TV6,
  PUBLIC_ADSENSE_STYLE_ID: process?.env?.PUBLIC_ADSENSE_STYLE_ID,
  PUBLIC_ADSENSE_TEST_MODE: process?.env?.PUBLIC_ADSENSE_TEST_MODE === 'true',
  PUBLIC_ASSET_PREFIX: process?.env?.PUBLIC_ASSET_PREFIX,
  PUBLIC_APP_ENVIRONMENT: process?.env?.PUBLIC_APP_ENVIRONMENT,
  PUBLIC_APP_VERSION: process?.env?.PUBLIC_APP_VERSION,
  PUBLIC_BLOCK_CRAWLERS: process?.env?.PUBLIC_BLOCK_CRAWLERS === 'true',
  PUBLIC_COMPANY_IMAGE_HOST: process?.env?.PUBLIC_COMPANY_IMAGE_HOST,
  PUBLIC_ENABLE_BLOG: process?.env?.PUBLIC_ENABLE_BLOG === 'true',
  PUBLIC_ENABLE_BROWSE_PAGE: process?.env?.PUBLIC_ENABLE_BROWSE_PAGE === 'true',
  PUBLIC_ENABLE_NOTIFICATIONS_MODAL: process?.env?.PUBLIC_ENABLE_NOTIFICATIONS_MODAL === 'true',
  PUBLIC_FRONTEND_HOST: process?.env?.PUBLIC_FRONTEND_HOST,
  PUBLIC_GOOGLE_MAPS_API_KEY: process?.env?.PUBLIC_GOOGLE_MAPS_API_KEY,
  PUBLIC_GQL_QUERY_TIMEOUT_MILLIS: parseInt(
    process?.env?.PUBLIC_GQL_QUERY_TIMEOUT_MILLIS || '60000'
  ),
  PUBLIC_GRAPHQL_BACKEND_ENDPOINT: process?.env?.PUBLIC_GRAPHQL_BACKEND_ENDPOINT,
  PUBLIC_GRAPHQL_GATEWAY_ENDPOINT: process?.env?.PUBLIC_GRAPHQL_GATEWAY_ENDPOINT,
  PUBLIC_GTM_CONTAINER_ID: process?.env?.PUBLIC_GTM_CONTAINER_ID,
  PUBLIC_LOCAL: process?.env?.PUBLIC_LOCAL === 'true',
  PUBLIC_MIXPANEL_TOKEN: process?.env?.PUBLIC_MIXPANEL_TOKEN,
  PUBLIC_SENTRY_DEBUG: process?.env?.PUBLIC_APP_ENVIRONMENT !== 'production',
  PUBLIC_SENTRY_DSN: process?.env?.PUBLIC_SENTRY_DSN,
  PUBLIC_TEST_ENV: process?.env?.PUBLIC_TEST_ENV === 'true',
  PUBLIC_STRIPE_PUBLISHABLE_API_KEY: process?.env?.PUBLIC_STRIPE_PUBLISHABLE_API_KEY
}

declare global {
  interface Window {
    __ENV__: PublicEnv
  }
}

const isServer = 'undefined' === typeof window

export default isServer || window.__ENV__ === undefined ? publicEnv : window.__ENV__
